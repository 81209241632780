import { BaseModel } from './baseModel';

export class ComissionadoModel extends BaseModel {
    nmComissionado: string;
    dsLogradouro: string;
    nuLogradouro: number;
    dsComplementoLogradouro: string;
    nuCEP: string;
    nmBairro: string;
    nmCidadeLogradouro: string;
    sgEstado: string;
    sgPais: string;
    edEmail: string;
    nuCPF: string;
    cdDDITelefone: number;
    nuTelefone: string;
    tpPessoa: string;
    nuCPFCNPJ: string;
    cdBanco: string;
    nuAgenciaBancaria: string;
    nuDigitoAgenciaBancaria: string;
    nuContaBancaria: string;
    nuDigitoContaBancaria: string;
    tpContaBancaria: string;
    cdPix: string;
    tpChavePix: string;
    flTransferirValor: boolean;
    flPixLiberado: boolean;
    dtNascimentoResponsavel: string;
    tpEmpresa: string;
    idAsaas: string;
    dlApiKeyAsaas: string;
    dlWalletIdAsaas: string;
    dsProblemaTransferencia: string;
    dsStatusDocumentacao: string;
    dsStatusGeral: string;
    cdNaturezaJuridica: string;

}
